<template>
  <section t-id="order-details" data-test-id="slide-in-panel" class="slide-in-panel-container">
    <section>
      <telia-heading tag="h2">{{ order.description }}</telia-heading>
      <telia-p>{{ order.orderId }}</telia-p>
    </section>
    <section class="status"><OrderDetailsStatus :order="order" /></section>

    <section class="support">
      <telia-notification v-if="successfulSubmitShown" status="success" heading-tag="h4">
        <span slot="heading">
          <telia-visually-hidden>{{ "NOTIFICATION.a11yStatus.error" }}</telia-visually-hidden>
          <strong>{{ t(`NOTIFICATION.submit.success.heading`, { caseNumber: caseId }) }}</strong>
        </span>
        <span slot="content">
          <telia-p>{{ t(`NOTIFICATION.submit.success.message`) }}</telia-p>
        </span>
      </telia-notification>
      <telia-grid v-else-if="!isSupportFormOpen">
        <telia-row>
          <telia-col width="12">
            <telia-button
              t-id="support-button"
              :text="t('ORDER_INFO.SUPPORT')"
              variant="secondary"
              full-width
              type="button"
              :disabled="orderClosed(order.orderStatus)"
              @click="toggleSupportForm()"
            />
          </telia-col>
        </telia-row>
      </telia-grid>

      <OrderDetailsSupport
        t-id="support-form"
        v-if="isSupportFormOpen && !successfulSubmitShown"
        :order="order"
        :successfulSubmitShown="successfulSubmitShown"
        :supportSubmitError="supportSubmitError"
        :isOrderModalOpen="isOrderModalOpen"
        :isSubmitting="isSubmitting"
        @cancelForm="cancelForm()"
      />
    </section>

    <section class="product">
      <telia-heading class="section-heading" tag="h3">{{
        t(`TYPE.${order.orderType}`)
      }}</telia-heading>
      <OrderDetailsProduct :scopeId="scopeId" :order="order" />
    </section>
    <section class="information"><OrderDetailsInformation :order="order" /></section>
    <section class="support-cases" v-if="!supportCasesLoading && caseList.length">
      <div v-if="!supportCasesLoading && caseList.length">
        <telia-heading class="section-heading" tag="h3">{{
          t("ORDER_INFO.SUPPORT_CASES.TITLE")
        }}</telia-heading>
        <div class="case-list">
          <div v-for="supportCase in caseList" :key="supportCase.caseId">
            <OrderSupportCase :scopeId="scopeId" :supportCase="supportCase" />
          </div>
        </div>
      </div>
      <telia-skeleton class="support-cases-skeleton" />
    </section>
  </section>
</template>

<script>
import { translateMixin } from "../../locale";
import {
  corpSupportReporter,
  supportCorpOrderCase,
  supportCorpAttachment,
} from "@telia/b2x-support-rest-client";

import OrderDetailsStatus from "./OrderDetailsStatus.vue";
import OrderDetailsProduct from "./OrderDetailsProduct.vue";
import OrderDetailsSupport from "./support/OrderDetailsSupport.vue";
import OrderDetailsInformation from "./OrderDetailsInformation.vue";
import OrderSupportCase from "./support/OrderSupportCase.vue";

const STATUS = {
  RECEIVED: "RECEIVED",
  ONGOING: "ONGOING",
  INCOMPLETE: "INCOMPLETE",
  DELIVERED: "DELIVERED",
  CANCELLED: "CANCELLED",
  CLOSED: "CLOSED",
};

export default {
  name: "order-details",
  mixins: [translateMixin],
  components: {
    OrderDetailsStatus,
    OrderDetailsInformation,
    OrderDetailsProduct,
    OrderDetailsSupport,
    OrderSupportCase,
  },
  data() {
    return {
      isSupportFormOpen: false,
      isRetryFormOpen: false,
      supportSubmitError: false,
      successfulSubmitShown: false,
      caseId: "",
      caseList: [],
      supportCasesLoading: true,
      supportCasesError: false,
      isSubmitting: false,
    };
  },
  props: {
    order: {
      type: Object,
    },
    scopeId: {
      type: String,
    },
    tscid: {
      type: String,
    },
    organizationNumber: {
      type: String,
    },
    isOrderModalOpen: {
      type: Boolean,
    },
  },
  watch: {
    order: {
      handler: "getOrderCaseList",
      immediate: true,
    },
  },

  methods: {
    onClose() {
      this.successfulSubmitShown = false;
    },
    orderClosed(status) {
      const closedStatuses = [STATUS.CLOSED];
      return closedStatuses.includes(status);
    },
    toggleSupportForm() {
      this.isSupportFormOpen = !this.isSupportFormOpen;
    },

    toggleRetryForm() {
      this.isRetryFormOpen = !this.isRetryFormOpen;
    },

    async getOrderCaseList() {
      this.caseList = [];
      this.supportCasesLoading = true;
      this.supportCasesError = false;
      try {
        if (this.order.uid) {
          const caseListResponse = await supportCorpOrderCase.OrderCaseControllerService.getOrderCaseList(
            this.order.uid,
            this.scopeId
          );
          this.caseList = caseListResponse.caseList;
        }
        this.supportCasesLoading = false;
      } catch (error) {
        this.supportCasesError = true;
        this.supportCasesLoading = false;
      }
    },

    async submitForm(formSubmit) {
      this.isSubmitting = true;
      const getSupportCategory = () => {
        return "DELIVERY";
      };

      try {
        const contact = await corpSupportReporter.CaseReporterControllerService.getReporter(
          this.scopeId,
          this.tscid
        );

        const requestBody = {
          orderUid: this.order.uid,
          category: getSupportCategory(formSubmit.subCategory),
          subCategory: formSubmit.subCategory,
          shortDescription: formSubmit.shortDescription,
          description: formSubmit.description,
          reporter: {
            preferredContactMethod: formSubmit.preferredContactMethod,
          },
        };
        if (!contact.fromSnow) {
          const { firstName, lastName, email, number } = contact;
          const formattedNumber = number ? this.formatNumber(number) : undefined;
          const { sysId } = await corpSupportReporter.CaseReporterControllerService.createReporter(
            this.scopeId,
            {
              firstName,
              lastName,
              email,
              mobile: formattedNumber,
              organizationNumber: this.organizationNumber,
            }
          );
          requestBody.reporter.sysId = sysId;
        } else {
          requestBody.reporter.sysId = contact.sysId;
        }
        const orderResponse = await supportCorpOrderCase.OrderCaseControllerService.createOrderCase(
          this.scopeId,
          requestBody
        );
        this.caseId = orderResponse.caseId;
        if (formSubmit.file) {
          await this.addFileToCase(this.caseId, formSubmit.file);
        }
        this.successfulSubmitShown = true;
        this.getOrderCaseList();

        // hide success notification after a few seconds
        setTimeout(() => {
          this.successfulSubmitShown = false;
          this.toggleSupportForm();
        }, 3000);
      } catch (error) {
        this.supportSubmitError = true;
      } finally {
        this.isSubmitting = false;
      }
    },
    async addFileToCase(caseId, file) {
      await supportCorpAttachment.AttachmentService.addAttachmentToCase(caseId, this.scopeId, {
        file,
      });
    },
    formatNumber(number) {
      return number.startsWith("0046") ? number.replace(/^(00)/gm, "+") : number;
    },
    cancelForm() {
      this.successfulSubmitShown = false;
      this.supportSubmitError = false;
      this.toggleSupportForm();
    },

    cancelRetry() {
      this.toggleRetryForm();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/borders/variables";
@import "~@teliads/components/foundations/colors/variables";

.case-list {
  border-top: $telia-border-width-1 $telia-gray-200 solid;
  margin-bottom: $telia-spacing-16;
}
.slide-in-panel-container {
  > *:not(.status) {
    padding-bottom: $telia-spacing-32;
  }
}

.section-heading {
  margin-bottom: $telia-spacing-8;
}

.retry {
  &__button {
    margin-top: $telia-spacing-16;
  }
}
</style>
