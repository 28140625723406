<template>
  <telia-grid>
    <telia-form name="supportForm" @submit.prevent="submit">
      <telia-fieldset :legend="t('SUPPORT.contact')">
        <telia-row>
          <telia-col width="12">
            <telia-select
              :label="t('SUPPORT.select')"
              name="supportType"
              :options="JSON.stringify(availableSupportTypes)"
              required
              @change="subCategory = $event.target.value"
            />
          </telia-col>
        </telia-row>

        <telia-row>
          <telia-col width="12">
            <div>
              <telia-text-input
                :label="t('SUPPORT.title')"
                name="title"
                type="text"
                required
                @input="shortDescription = $event.target.value"
              />
            </div>
          </telia-col>
        </telia-row>

        <telia-row>
          <telia-col width="12">
            <div>
              <telia-textarea
                :label="t('SUPPORT.describe')"
                name="description"
                rows="4"
                required
                @input="description = $event.target.value"
              />
            </div>
          </telia-col>
        </telia-row>

        <telia-row>
          <telia-col width="12">
            <telia-fieldset
              :legend="t('SUPPORT.contactMethod')"
              name="preferredContactMethod"
              required=""
            >
              <telia-radio-button
                name="preferredContactMethod"
                :label="t('SUPPORT.MY_BUSINESS')"
                value="WEB_PORTAL"
                @change="preferredContactMethod = $event.target.value"
              ></telia-radio-button>
              <telia-radio-button
                name="preferredContactMethod"
                :label="t('SUPPORT.EMAIL')"
                value="EMAIL"
                @change="preferredContactMethod = $event.target.value"
              ></telia-radio-button>
              <telia-radio-button
                name="preferredContactMethod"
                :label="t('SUPPORT.SMS')"
                value="SMS"
                @change="preferredContactMethod = $event.target.value"
              ></telia-radio-button
            ></telia-fieldset>
          </telia-col>
        </telia-row>

        <telia-row spacing="md">
          <telia-col width="3">
            <div>
              <telia-button variant="secondary" size="sm">
                <telia-icon name="attachment" slot="left" size="sm" />
                {{ t("SUPPORT.attach") }}
                <input
                  type="file"
                  class="input-file"
                  :accept="hasAllowedFileType"
                  @change="onFileChange($event)"
                />
              </telia-button>
            </div>
          </telia-col>

          <telia-col width="9" class="file-text-wrapper">
            <div class="file-container" v-if="file">
              <telia-p class="file-text">{{ file.name }}</telia-p>
              <telia-icon class="remove-file" name="close" size="md" @click="removeFile" />
            </div>
            <div class="file-container" v-if="fileSizeError">
              <telia-p class="file-text">{{ t("SUPPORT.file_size_error") }}</telia-p>
            </div>
          </telia-col>
        </telia-row>

        <telia-notification v-show="supportSubmitError" status="error" heading-tag="h4">
          <span slot="heading">
            <telia-visually-hidden>{{ "NOTIFICATION.a11yStatus.error" }}</telia-visually-hidden>
            <strong>{{ t(`NOTIFICATION.submit.error.heading`) }}</strong>
          </span>
          <span slot="content">
            <telia-p>{{ t(`NOTIFICATION.submit.error.message`) }}</telia-p>
          </span>
        </telia-notification>

        <telia-row>
          <telia-col width="12">
            <div class="button-wrapper">
              <div>
                <b2x-loading-button
                  full-width
                  :disabled="submitDisabled"
                  :is-loading="isSubmitting"
                  size="sm"
                  variant="primary"
                  type="submit"
                >
                  <span>{{ t("SUPPORT.send") }}</span>
                </b2x-loading-button>
              </div>
              <div>
                <telia-button
                  :text="t('SUPPORT.cancel')"
                  full-width
                  size="sm"
                  variant="secondary"
                  @click.prevent="cancel"
                />
              </div>
            </div>
          </telia-col>
        </telia-row>
      </telia-fieldset>
    </telia-form>
  </telia-grid>
</template>

<script>
import { translateMixin } from "../../../locale";
import { FileValidations } from "../../../helper/FileHelper";
import "@telia/b2x-loading-button";

const supportTypeOptions = [
  { value: "placeholder", selected: true, disabled: true },
  { value: "CHANGE_ORDER", selected: false, disabled: false },
  { value: "CHANGE_DELIVERY_DATE", selected: false, disabled: false },
  { value: "CANCEL_ORDER", selected: false, disabled: false },
  { value: "OTHER_QUESTION", selected: false, disabled: false },
];

const supportTypeOptionsDelivered = [
  { value: "placeholder", selected: true, disabled: true },
  { value: "DELIVERY_COMPLAINT", selected: false, disabled: false },
  { value: "OTHER_QUESTION", selected: false, disabled: false },
];

export default {
  name: "order-details-support",
  mixins: [translateMixin],
  props: {
    supportSubmitError: {
      type: Boolean,
    },
    order: {
      type: Object,
    },
    isOrderModalOpen: {
      type: Boolean,
    },
    isSubmitting: {
      type: Boolean,
    },
  },
  data() {
    return {
      subCategory: "",
      shortDescription: "",
      description: "",
      preferredContactMethod: "",
      file: null,
      fileSizeError: false,
    };
  },
  watch: {
    isOrderModalOpen: {
      handler: "onModalOpenChange",
      immediate: true,
    },
  },
  computed: {
    availableSupportTypes() {
      if (this.order?.orderStatus === "DELIVERED" || this.order?.orderStatus === "CANCELLED") {
        return supportTypeOptionsDelivered.map((option) => ({
          ...option,
          label:
            option.value === "OTHER_QUESTION"
              ? this.t(`SUPPORT.CATEGORY.DELIVERY_QUESTION`)
              : this.t(`SUPPORT.CATEGORY.${option.value}`),
        }));
      }
      return supportTypeOptions.map((option) => ({
        ...option,
        label: this.t(`SUPPORT.CATEGORY.${option.value}`),
      }));
    },
    submitDisabled() {
      return (
        !this.subCategory ||
        this.subCategory === "placeholder" ||
        !this.shortDescription ||
        !this.description ||
        !this.preferredContactMethod ||
        this.fileSizeError
      );
    },
    hasAllowedFileType() {
      return FileValidations.allowedTypes.join(",");
    },
  },
  methods: {
    async onFileChange(event) {
      this.removeFile();
      const file = event.target.files[0];
      if (file?.size > FileValidations.maxSize) {
        this.fileSizeError = true;
        return;
      }
      this.file = file;
    },
    removeFile() {
      this.file = null;
      this.fileSizeError = false;
    },
    onModalOpenChange() {
      if (!this.isOrderModalOpen) {
        this.cancel();
      }
    },
    submit() {
      const supportRequest = {
        subCategory: this.subCategory,
        shortDescription: this.shortDescription,
        description: this.description,
        preferredContactMethod: this.preferredContactMethod,
        file: this.file,
      };
      this.$emit("submitForm", supportRequest);
    },
    cancel() {
      this.$emit("cancelForm");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/borders/variables";
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/colors/tokens";
@import "~@teliads/components/foundations/motion/variables";
@import "~@teliads/components/foundations/shadows/variables";

telia-grid {
  border-top: $telia-border-width-1 solid $telia-gray-300;
  border-bottom: $telia-border-width-1 solid $telia-gray-300;
  padding: 0;
  telia-row:not(:last-of-type) {
    margin-bottom: $telia-spacing-16;
  }
}
telia-fieldset {
  margin-bottom: 0;
}
telia-select {
  margin: 0;
}
.button-wrapper {
  div:not(:last-of-type) {
    margin-bottom: $telia-spacing-16;
  }
}
.input-file {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.file-text-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .file-container {
    display: flex;
    align-items: center;
    //margin-bottom: $telia-spacing-16;

    .file-text {
      margin-bottom: 0;
      margin-right: $telia-spacing-8;
    }
    .remove-file {
      cursor: pointer;
      border-radius: 100%;
      transition: 300ms;
      &:hover {
        background-color: $telia-purple-300;
        box-shadow: $telia-shadow-md;
      }
    }
  }
}
</style>
