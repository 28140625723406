<template>
  <div>
    <telia-skeleton v-if="isLoading" class="retry__date-picker-skeleton"></telia-skeleton>
    <telia-date-picker
      v-if="!isLoading"
      data-testid="nm-input-datePicker"
      :label="t('RETRY.datePicker')"
      required
      :isDateDisabled="isDateDisabledHandler"
      :value="moveDate"
      @vocaChange="(event) => setMoveDate(event.target.value)"
    ></telia-date-picker>
  </div>
</template>

<script setup lang="ts">
import { translate as t } from "@telia/b2b-i18n";
import { corpWorkdayCalendarService } from "@telia/b2b-rest-client";
import { onMounted, ref } from "vue";

interface Props {
  scopeId: string;
}

const emit = defineEmits<{
  (e: "move-date", moveDate: string): void;
}>();

const props = defineProps<Props>();

const isLoading = ref<boolean>(false);

const holidays = ref<string[]>([]);
const nextWorkday = ref<string>();
const moveDate = ref<string>();

const DAYS_TO_OFFSET = 5;

onMounted(async () => {
  isLoading.value = true;
  await Promise.all([getNextWorkday(), getHolidays()]);
  isLoading.value = false;
});

async function getNextWorkday() {
  try {
    const result = await corpWorkdayCalendarService.DatePickerControllerService.getNextEligibleWorkday(
      props.scopeId,
      DAYS_TO_OFFSET
    );
    nextWorkday.value = result.workday;
    setMoveDate(result.workday);
  } catch (error) {
    console.log("Error fetching workdays");
  }
}

async function getHolidays() {
  try {
    holidays.value = (
      await corpWorkdayCalendarService.DatePickerControllerService.holidays(props.scopeId)
    ).holidays;
  } catch (error) {
    console.log("Error fetching holidays");
  }
}

const setMoveDate = (_value: string) => {
  moveDate.value = _value;
  emit("move-date", _value);
};

const isDateDisabledHandler = (date) => {
  const newDate = new Date(date);
  const todayFormatted = formatDate(newDate);

  const ninetyDaysFromNow = new Date();
  ninetyDaysFromNow.setDate(ninetyDaysFromNow.getDate() + 90);

  let beforeFirstAvailable = false;

  if (nextWorkday.value) {
    const nextWorkdayFormatted = new Date(nextWorkday.value);
    nextWorkdayFormatted.setHours(0);

    newDate.getTime() < nextWorkdayFormatted.getTime()
      ? (beforeFirstAvailable = true)
      : (beforeFirstAvailable = false);
  }

  return (
    date.getDay() === 6 ||
    date.getDay() === 0 ||
    holidays.value.includes(todayFormatted) ||
    beforeFirstAvailable ||
    newDate >= ninetyDaysFromNow
  );
};

const formatDate = (date: Date) => {
  return [
    date.getFullYear(),
    ("0" + (date.getMonth() + 1)).slice(-2),
    ("0" + date.getDate()).slice(-2),
  ].join("-");
};
</script>

<style lang="scss">
.retry__date-picker-skeleton {
  height: 5rem;
}
</style>
