<template>
  <div t-id="product-details" class="product">
    <div :class="wrapperClass" @click="expandToggle">
      <div class="description-wrapper">
        <div class="heading-wrapper">
          <telia-heading tag="h4" variant="title-100">{{ getProductName(product) }}</telia-heading>
        </div>
        <div v-if="product.quantity && product.quantity > 1">
          <telia-p>{{
            t("ORDER_INFO.PRODUCTS_SECTION.QUANTITY", { quantity: product.quantity })
          }}</telia-p>
        </div>
        <div class="status-badge-wrapper">
          <telia-status-badge
            v-if="product.status"
            :variant="getStatusBadgeVariant(product.status)"
          >
            {{ t(`ORDER_STATUS.${product.status}`) }}
          </telia-status-badge>
        </div>
      </div>
      <div class="toggle-icon">
        <telia-icon
          v-if="isExpandable"
          :name="expanded ? 'chevron-up' : 'chevron-down'"
          size="md"
        />
        <telia-p>{{ getPricingText(product.pricing) }}</telia-p>
      </div>
    </div>
    <DetailsExpander>
      <div v-show="expanded" class="details-wrapper">
        <div class="image-wrapper" v-if="couldGetImage(product)">
          <telia-image :src="getProductImageUrl(product)" :alt="product.offeringName" />
        </div>
        <div class="details">
          <div v-if="product.parcels.length">
            <telia-p>{{ t("ORDER_INFO.PRODUCTS_SECTION.PARCEL_ID") }}:</telia-p>
            <div v-for="parcel in product.parcels" :key="parcel.parcelId">
              <telia-link v-if="parcel.trackingUrl" :href="parcel.trackingUrl" target="_blank">
                <telia-p>
                  {{ t(`ORDER_INFO.PRODUCTS_SECTION.TRACE_PARCEL`, { id: parcel.parcelId }) }}
                </telia-p>
              </telia-link>
              <telia-p v-else>{{ parcel.parcelId }}</telia-p>
            </div>
          </div>

          <div v-if="product.imeiNumber">
            <telia-p>
              {{ `${t("ORDER_INFO.PRODUCTS_SECTION.IMEI_NUMBER")}: ${product.imeiNumber}` }}
            </telia-p>
          </div>

          <div class="subscriptionId" v-if="orderType !== 'INPORTING'">
            <telia-p>{{ product.subscriberName }}</telia-p>
            <telia-p>{{ formatPhoneNumber(product.subscriptionId) }}</telia-p>
          </div>

          <div v-if="orderType === 'INPORTING' && product.numberMigration">
            <telia-p>
              {{
                t("ORDER_INFO.PRODUCTS_SECTION.PORTING_NUMBER", {
                  number: product.numberMigration.target,
                })
              }}
            </telia-p>
            <telia-p>
              {{
                t("ORDER_INFO.PRODUCTS_SECTION.TEMPORARY_NUMBER", {
                  number: product.numberMigration.temporary,
                })
              }}
            </telia-p>
          </div>

          <div v-if="product.ipRangeStart">
            <telia-p>
              {{
                t("ORDER_INFO.PRODUCTS_SECTION.IP_RANGE_START", {
                  ip: product.ipRangeStart,
                })
              }}
            </telia-p>
          </div>

          <div v-if="showShippingAddress">
            <telia-p>{{ t("ORDER_INFO.PRODUCTS_SECTION.SHIPPING_ADDRESS") }} </telia-p>
            <telia-p>{{ product.shippingAddress.street }}</telia-p>
            <telia-p>{{ product.shippingAddress.zipCode }}</telia-p>
            <telia-p>{{ product.shippingAddress.city }}</telia-p>
          </div>

          <div v-if="showInstallationAddress">
            <telia-p>{{ t("ORDER_INFO.PRODUCTS_SECTION.INSTALLATION_ADDRESS") }} </telia-p>
            <telia-p>{{ product.installationAddress.street }}</telia-p>
            <telia-p>{{ product.installationAddress.zipCode }}</telia-p>
            <telia-p>{{ product.installationAddress.city }}</telia-p>
          </div>

          <div v-if="product.promisedDeliveryDate">
            <telia-p>
              {{
                `${t("ORDER_INFO.PRODUCTS_SECTION.PROMISED_DELIVERY_DATE")} ${
                  product.promisedDeliveryDate
                }`
              }}
            </telia-p>
          </div>

          <div
            v-if="product.productCategory === 'mobilevoicesubscription' && product.commitmentTime"
          >
            <telia-p v-if="product.commitmentTime > 0">
              {{
                `${t("ORDER_INFO.PRODUCTS_SECTION.COMMITMENT_TIME", {
                  time: product.commitmentTime,
                })}`
              }}
            </telia-p>
            <telia-p v-else>
              {{ t("ORDER_INFO.PRODUCTS_SECTION.NO_COMMITMENT_TIME") }}
            </telia-p>
          </div>
          <div>
            <telia-p v-if="product.pricing && recurringFee > 0">
              {{
                t("ORDER_INFO.PRODUCTS_SECTION.PRICING.INCREASED_FEE", {
                  fee: product.pricing.increasedRecurringFeeForPhone,
                })
              }}
            </telia-p>
          </div>

          <div class="addons-wrapper" v-if="product.addOns">
            <div class="added" v-if="product.addOns.added && product.addOns.added.length">
              <telia-heading tag="h5" variant="subsection-100">{{
                t("ORDER_INFO.PRODUCTS_SECTION.ACTIVATED")
              }}</telia-heading>
              <div v-for="(addOn, i) in product.addOns.added" :key="i">
                <div class="addon-container">
                  <telia-p>{{ addOn.name }}</telia-p>
                  <telia-p>{{ getPricingText(addOn) }}</telia-p>
                </div>
              </div>
            </div>

            <div class="removed" v-if="product.addOns.deleted && product.addOns.deleted.length">
              <telia-heading tag="h5" variant="subsection-100">{{
                t("ORDER_INFO.PRODUCTS_SECTION.INACTIVATED")
              }}</telia-heading>
              <div v-for="(addOn, i) in product.addOns.deleted" :key="i">
                <div class="addon-container">
                  <telia-p>{{ addOn.name }}</telia-p>
                  <telia-p>{{ getPricingText(addOn) }}</telia-p>
                </div>
              </div>
            </div>
          </div>

          <div v-if="product.pricing" class="pricing-wrapper">
            <div
              v-if="orderType === 'CHANGE' && getPricingText(product.pricing)"
              class="pricing-container"
            >
              <telia-p>
                {{ t("ORDER_INFO.PRODUCTS_SECTION.PRICING.TOTAL_PRICE_CHANGE") }}
              </telia-p>
              <telia-p>
                {{ getPricingText(product.pricing) }}
              </telia-p>
            </div>
          </div>
          <div v-if="order.resendPortInPossible">
            <OrderDetailsRetry :scope-id="scopeId" :order="order" />
          </div>
        </div>
      </div>
    </DetailsExpander>
  </div>
</template>

<script>
import { currentLanguage, translateMixin } from "../../locale";
import { formatPhoneNumber } from "@telia/b2x-phone-number-format";
import DetailsExpander from "./DetailsExpander";
import OrderDetailsRetry from "./retry/OrderDetailsRetry.vue";
import simcard from "../../../assets/simcard.png";

export default {
  name: "order-details-product",
  components: { DetailsExpander, OrderDetailsRetry },
  mixins: [translateMixin],
  data() {
    return {
      simcard,
      formatPhoneNumber,
      expanded: false,
    };
  },
  props: {
    scopeId: {
      type: String,
    },
    order: {
      type: Object,
    },
    product: {
      type: Object,
    },
    orderType: {
      type: String,
    },
  },
  computed: {
    showShippingAddress() {
      const { productCategory, shippingAddress } = this.product;
      const { city, country, street, zipCode } = shippingAddress;
      return productCategory !== "fixedbroadband" && (city || country || street || zipCode);
    },
    showInstallationAddress() {
      const { productCategory, installationAddress } = this.product;
      const { city, country, street, zipCode } = installationAddress;
      return productCategory === "fixedbroadband" && (city || country || street || zipCode);
    },
    recurringFee() {
      return Number(this.product.pricing.increasedRecurringFeeForPhone);
    },
    isExpandable() {
      return (
        this.product.subscriptionId ||
        this.product.parcels.length ||
        this.product.imeiNumber ||
        this.showShippingAddress ||
        this.showInstallationAddress ||
        this.product.promisedDeliveryDate ||
        this.product.addOns ||
        this.product.pricing ||
        (this.product.productCategory === "mobilevoicesubscription" && this.product.commitmentTime)
      );
    },
    wrapperClass() {
      if (this.isExpandable) {
        return "product-header-wrapper expandable";
      } else {
        return "product-header-wrapper";
      }
    },
  },
  methods: {
    expandToggle() {
      if (this.isExpandable) {
        this.expanded = !this.expanded;
      }
    },
    couldGetImage(product) {
      return !!this.getProductImageUrl(product);
    },
    getProductImageUrl(product) {
      return product.contentful?.imageUrl
        ? `${product.contentful.imageUrl}?w=60`
        : product.productCategory === "mobilevoicesubscription" ||
          product.productCategory === "mobilebroadbandsubscription"
        ? this.simcard
        : null;
    },
    getProductName(product) {
      const lang = currentLanguage();
      return product.contentful?.name[lang] ? product.contentful?.name[lang] : product.offeringName;
    },
    getDetails(product) {
      return [product.contentful?.memory, product.contentful?.color[currentLanguage()]]
        .filter(Boolean)
        .join(", ");
    },
    getPricingText(item) {
      if (!item) {
        return "";
      }
      const pricingStr = [];
      if (item.oneTimeFeeAmount > 0) {
        pricingStr.push(
          this.t("ORDER_INFO.PRODUCTS_SECTION.PRICING.ONE_TIME_FEE", {
            cost: item.oneTimeFeeAmount,
          })
        );
      }
      if (item.recurringChargeAmount > 0 || item.recurringChargeFee > 0) {
        pricingStr.push(
          this.t("ORDER_INFO.PRODUCTS_SECTION.PRICING.PRICE_PER_MONTH", {
            cost: item.recurringChargeAmount ?? item.recurringChargeFee,
          })
        );
      }
      return pricingStr.join(" + ");
    },
    getStatusBadgeVariant(orderStatus) {
      if (orderStatus) {
        const positiveOrderStatuses = ["DELIVERED", "RESOLVED"];
        const informativeOrderStatuses = ["RECEIVED", "ONGOING", "AWAITING_INFO"];
        const cautionaryOrderStatuses = [
          "INCOMPLETE",
          "OUT_OF_STOCK",
          "WAITING_FOR_SIGNING_AGREEMENTS",
        ];
        const warningOrderStatuses = ["CANCELLED", "CLOSED"];
        const neutralOrderStatuses = ["UNKNOWN"];

        if (positiveOrderStatuses.indexOf(orderStatus) !== -1) {
          return "positive";
        } else if (informativeOrderStatuses.indexOf(orderStatus) !== -1) {
          return "information";
        } else if (cautionaryOrderStatuses.indexOf(orderStatus) !== -1) {
          return "caution";
        } else if (warningOrderStatuses.indexOf(orderStatus) !== -1) {
          return "warning";
        } else if (neutralOrderStatuses.indexOf(orderStatus) !== -1) {
          return "neutral";
        }
      }

      return "neutral";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/borders/variables";
@import "~@teliads/components/foundations/colors/variables";

.product {
  display: flex;
  margin-left: $telia-spacing-16;
  flex-direction: column;
  .product-header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.expandable {
      cursor: pointer;
      &:hover {
        color: $telia-purple-500;
      }
    }
  }

  .heading-wrapper {
    display: flex;
  }

  .status-badge-wrapper {
    margin: $telia-spacing-4 0;
  }

  .toggle-icon {
    display: flex;
    flex-direction: column;
    align-items: end;
    white-space: nowrap;
  }

  .details-wrapper {
    display: flex;
    > div {
      padding-top: $telia-spacing-8;
    }

    .details {
      flex: 1;
    }

    .addons-wrapper {
      margin: $telia-spacing-12 0 $telia-spacing-4 0;
      > div:first-of-type {
        margin-bottom: $telia-spacing-8;
      }
      .added,
      .removed {
        > div {
          margin: 0 0 $telia-spacing-8 0;

          .addon-container {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }

    .pricing-wrapper {
      margin: $telia-spacing-12 0 $telia-spacing-4 0;

      .pricing-container {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  > div {
    .image-wrapper,
    .icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: $telia-spacing-12;
      max-width: $telia-spacing-96;
      min-width: $telia-spacing-96;
      min-height: $telia-spacing-96;
      max-height: $telia-spacing-96;
      telia-icon {
        min-width: $telia-spacing-48;
        min-height: $telia-spacing-48;
      }
    }
  }
}
</style>
