<template>
  <telia-grid class="retry">
    <telia-row>
      <telia-notification class="retry__reason" status="error" heading-tag="h4">
        <span slot="heading">
          <telia-visually-hidden>{{ "NOTIFICATION.a11yStatus.error" }}</telia-visually-hidden>
          <strong>{{ cancelReason }}</strong>
        </span>
      </telia-notification>
    </telia-row>

    <telia-row>
      <telia-col width="12">
        <div class="retry__number">
          <telia-heading tag="h3" variant="subsection-100">{{ t("RETRY.number") }} </telia-heading>
          <telia-p>{{ targetNumber }}</telia-p>
        </div>
        <DatePicker v-if="status !== 'success'" :scopeId="scopeId" @move-date="setDate" />
      </telia-col>
    </telia-row>

    <telia-row>
      <telia-col width="12">
        <div v-if="status !== 'success'" class="button-wrapper">
          <div>
            <b2x-loading-button
              full-width
              :disabled="submitDisabled"
              :is-loading="isSubmitting"
              size="sm"
              variant="primary"
              @click="retry"
            >
              <span>{{ t("RETRY.send") }}</span>
            </b2x-loading-button>
          </div>
        </div>
        <div>
          <telia-notification v-if="status === 'success'" status="success" heading-tag="h4">
            <span slot="heading">
              <telia-visually-hidden>{{ "NOTIFICATION.a11yStatus.success" }}</telia-visually-hidden>
              <strong>{{ t("RETRY.success") }}</strong>
            </span>
          </telia-notification>
          <telia-notification v-if="status === 'error'" status="error" heading-tag="h4">
            <span slot="heading">
              <telia-visually-hidden>{{ "NOTIFICATION.a11yStatus.error" }}</telia-visually-hidden>
              <strong>{{ t("RETRY.error") }}</strong>
            </span>
          </telia-notification>
        </div>
      </telia-col>
    </telia-row>
  </telia-grid>
</template>

<script>
import { translateMixin, currentLanguage } from "../../../locale";
import { corpNumberMigration } from "@telia/b2b-rest-client";
import DatePicker from "./DatePicker";
import "@telia/b2x-loading-button";

const STATUS = {
  success: "success",
  error: "error",
  idle: "idle",
};

export default {
  name: "order-details-retry",
  mixins: [translateMixin],
  components: {
    DatePicker,
  },
  props: {
    scopeId: {
      type: String,
    },
    order: {
      type: Object,
    },
  },
  data() {
    return {
      moveDate: "",
      isSubmitting: false,
      status: STATUS.idle,
    };
  },
  computed: {
    targetNumber() {
      return this.order.mainOrderItems.find((item) => {
        return item.productSubCategory === "portin";
      }).numberMigration.target;
    },
    cancelReason() {
      if (this.order.microcopy) {
        return this.order.microcopy.cancelReason
          ? this.order.microcopy.cancelReason[currentLanguage()]
          : undefined;
      } else {
        return undefined;
      }
    },
  },

  methods: {
    setDate(date) {
      this.moveDate = date;
    },

    async retry() {
      this.status = STATUS.idle;
      const targetNumber = this.targetNumber;

      if (!targetNumber) {
        return;
      }
      this.isSubmitting = true;
      try {
        await corpNumberMigration.NumberMigrationControllerService.resendPortInOrder(
          this.scopeId,
          targetNumber,
          this.moveDate
        );
        this.status = STATUS.success;
      } catch (e) {
        this.status = STATUS.error;
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";

.retry {
  padding: $telia-spacing-16 0 $telia-spacing-16;
  telia-row:not(:last-of-type) {
    margin-bottom: $telia-spacing-16;
  }
  &__number {
    padding: $telia-spacing-16 0 $telia-spacing-16;
  }
}
.button-wrapper {
  div {
    margin-bottom: $telia-spacing-16;
  }
}
</style>
